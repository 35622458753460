import isEmpty from "lodash/isEmpty";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";

import { Icon } from "~assets";
import { cn } from "~utils";

type AcceptType =
  | ".zip"
  | ".csv"
  | ".pdf"
  | ".xlsx"
  | ".xls"
  | ".doc"
  | ".docx";

export type DropzoneProps = React.PropsWithChildrenRequired<{
  dropText: string;
  maxSize?: number;
  isDisabled?: boolean;
  acceptType?: AcceptType | AcceptType[];
}> &
  (
    | {
        isMultiple?: false;
        value: File | undefined;
        onChange: (
          acceptedFile: File | undefined,
          fileRejection: FileRejection | undefined,
        ) => void;
      }
    | {
        isMultiple: true;
        value: File[] | undefined;
        onChange: (
          acceptedFiles: File[] | undefined,
          fileRejections: FileRejection[] | undefined,
        ) => void;
      }
  ) &
  DropzoneOptions;

const generateAccept = (acceptType: AcceptType | AcceptType[]) => ({
  // accepts zip files
  ...(acceptType.includes(".zip") && { "application/*": [".zip"] }),

  // accepts csv files, xls, xlsx files
  ...((acceptType.includes(".csv") ||
    acceptType.includes(".xlsx") ||
    acceptType.includes(".xls")) && {
    "text/*": Array.isArray(acceptType)
      ? acceptType.filter(type => [".csv", ".xlsx", ".xls"].includes(type))
      : [acceptType],
  }),

  //accepts doc files
  ...(acceptType.includes(".doc") && { "application/msword": [".doc"] }),

  //accepts docx files
  ...(acceptType.includes(".docx") && {
    "application/*": [".docx"],
  }),

  // accepts PDF files
  ...(acceptType.includes(".pdf") && { "application/pdf": [".pdf"] }),
});

export const Dropzone = ({
  value,
  children,
  dropText,
  maxSize,
  isDisabled,
  isMultiple,
  onChange,
  acceptType,
  ...rest
}: DropzoneProps) => {
  const handleFileDropped = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (isMultiple) {
      const updatedFiles = [...(value ?? []), ...(acceptedFiles ?? [])];

      onChange(
        isEmpty(updatedFiles) ? undefined : updatedFiles,
        isEmpty(fileRejections) ? undefined : fileRejections,
      );
    } else {
      onChange(
        isEmpty(acceptedFiles) ? undefined : acceptedFiles[0],
        isEmpty(fileRejections) ? undefined : fileRejections[0],
      );
    }
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: acceptType ? generateAccept(acceptType) : undefined,
    ...rest,
    // Maximum file size (in bytes)
    maxSize: maxSize ? maxSize * 1000000 : undefined,
    multiple: isMultiple,
    noClick: isDisabled,
    noDrag: isDisabled,
    onDrop: handleFileDropped,
  });

  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center rounded border border-dashed border-accent-100 p-4",
        {
          "border-accent-100 bg-accent-100": isDragActive,
          "border-accent-100 bg-grey-white": !isDragActive && !isDisabled,
          "cursor-not-allowed border-grey-300 bg-grey-300": isDisabled,
          "cursor-pointer": !isDisabled,
        },
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <div className="relative h-full">
        <div className={isDragActive ? "pointer-events-none opacity-0" : ""}>
          {children}
        </div>

        <div
          className={
            isDragActive
              ? "flex-column absolute top-0 flex h-full w-full items-center justify-center"
              : "hidden"
          }
        >
          <Icon
            name="file-arrow-up"
            className="rounded bg-grey-white p-3 text-h3 text-grey-black"
          />

          <h5 className="text-p1 font-bold text-grey-white">{dropText}</h5>
        </div>
      </div>
    </div>
  );
};
